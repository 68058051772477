import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { ToastService } from '../toast/toast.service';
import { AuthorizationActions } from 'src/app/store/actions';
import { MonitoringService } from './monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends ErrorHandler {
  private readonly toastService = inject(ToastService);
  private readonly store = inject(Store);
  public readonly router = inject(Router);
  /**
   * @ignore
   */
  constructor(private monitoringService: MonitoringService) {
    super();
  }

  handle(error: Error, showMessage = true, canRedirect = true) {
    this.monitoringService.logException(error);

    if (!showMessage) {
      return;
    }
    const messageTitle = 'Error';
    if (error instanceof HttpErrorResponse) {
      if (error.error?.message) {
        this.toastService.error(error.error.message, messageTitle);
      }
      switch (error.status) {
        case HttpStatusCode.Unauthorized:
          this.store.dispatch(
            AuthorizationActions.setAccountInfo({ accountInfo: null })
          );
          break;
        case HttpStatusCode.NotFound:
          if (canRedirect) {
            this.router.navigate(['/non-found']);
          }
          break;
        case HttpStatusCode.Forbidden:
          if (canRedirect) {
            this.router.navigate(['/access-denied']);
          }
          break;
      }
    }
  }
}
